export enum QueryKey {
  COMPANIES = 'pa-shell-companies',
  PROFILES = 'pa-shell-profiles',
  INIT_COMPANY = 'pa-shell-init-company',
  INIT_USER = 'pa-shell-init-user',
  INIT_SCOPES = 'pa-shell-init-scopes',
  INIT_LEGACY_USER = 'pa-shell-init-legacy-user', // TODO: remove after deleting connections with old PA (iframe)
  USER_CARD = 'pa-shell-user-card',
  USERS_TABLE = 'pa-shell-users-table',
}
