import { type FC, lazy, Suspense, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Navigate, useRoutes as useBaseRoutes } from 'react-router-dom';
import {
  Loader,
  Path,
  ScopeAccessRoute,
  WithUiContexts,
} from '@omnipkg/pa-ui-kit';
import { BillingApp } from 'billing/lib';
import { DeliveryApp } from 'delivery/lib';
import { DevicesApp } from 'devices/lib';

import BaseHoc from './hoc/BaseHoc';
import InitialLayout from './layouts/InitialLayout';
import { Routes } from './types/routes';

import './styles/index.scss';
import '@omnic/lk-ui/dist/style.css';

const apiUrl = import.meta.env.VITE_TOLGEE_API_URL;
const apiKey = import.meta.env.VITE_TOLGEE_API_KEY;

const Users = lazy(() => import('./pages/Users'));

ReactGA.initialize('G-WS4VVNWVRD');

function useRoutes() {
  return useBaseRoutes([
    {
      path: Path.root,
      element: <InitialLayout />,
      children: [
        {
          index: true,
          element: <Navigate to={Path.devices} />,
        },
        {
          path: Routes.USERS,
          element: (
            <Suspense fallback={<Loader fullScreen />}>
              <Users />
            </Suspense>
          ),
        },

        // --- devices microservice ---
        {
          element: <ScopeAccessRoute access="cc:pc:allowed" />,
          children: [
            {
              path: Path.devices,
              children: DevicesApp.router,
            },
          ],
        },

        // --- billing microservice ---
        {
          element: <ScopeAccessRoute access="de:billing:user" />,
          children: [
            {
              path: Path.billing,
              children: BillingApp.router,
            },
          ],
        },

        // --- delivery microservice ---
        {
          element: <ScopeAccessRoute access="ec:pc:allowed" />,
          children: [
            {
              path: Path.delivery,
              children: DeliveryApp.router,
            },
          ],
        },
      ],
    },
    {
      path: Path.login,
      element: <Navigate to={Path.root} />,
    },
  ]);
}

export const App: FC = () => {
  const routes = useRoutes();
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  }, []);

  return (
    <WithUiContexts
      translationSettings={{
        apiKey,
        apiUrl,
      }}
      themeSettings={{
        primary: true,
      }}
    >
      <BaseHoc>{routes}</BaseHoc>
    </WithUiContexts>
  );
};
