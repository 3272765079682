import { lazy, Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Loader } from '@omnipkg/pa-ui-kit';

import { useCommonContext } from '@src/context/CommonContext';

export const MainLayout = lazy(() => import('../MainLayout'));

export default function InitialLayout(): JSX.Element {
  const { isDataReady } = useCommonContext();

  useEffect(() => {
    if (isDataReady) {
      const fallbackElement = document.getElementById('om-fallback-loader');
      if (fallbackElement) {
        fallbackElement.style.display = 'none';
      }
    }
  }, [isDataReady]);

  return isDataReady ? (
    <Suspense fallback={<Loader fullScreen />}>
      <MainLayout>
        <Outlet />
      </MainLayout>
    </Suspense>
  ) : (
    <Loader fullScreen />
  );
}
