import { useCallback } from 'react';
import { CommonResponse, useApiContext, User } from '@omnipkg/pa-ui-kit';

export function useGetUserLegacy() {
  const { request } = useApiContext();

  return useCallback(async (): Promise<User> => {
    const { data } = await request<CommonResponse<User>, Request>({
      url: 'users/current/',
    });

    return data.data;
  }, [request]);
}
