import { lazy, Suspense } from 'react';
import { Toaster } from 'react-hot-toast';
import { Loader } from '@omnipkg/pa-ui-kit';

import { HocProps } from '../types';

const LazyHock = lazy(() => import('../LazyHoc'));

export default function BaseHoc({ children }: HocProps): JSX.Element {
  return (
    <Suspense fallback={<Loader fullScreen />}>
      <Toaster position="top-right" />
      <LazyHock>{children}</LazyHock>
    </Suspense>
  );
}
